import "styles/pages/page-thank-you.scss";

import React from "react";
import { Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";

const ThanksPage = () => {
  return (
    <Layout>
      <Seo title="Kontakt potwierdzenie" />
      <section className="page-thanks">
        <div className="container">
          <h1 className="page-thanks__title">Dziękujemy</h1>
          <div className="page-thanks__text">
            <strong>
              za skontaktowanie się z naszą poradnią. Twoja wiadomość już do nas
              dotarła.
            </strong>
            Skontaktujemy się z Tobą najszybciej jak to tylko możliwe.
          </div>
          <Link to="/" className="button button--green">
            Powrót na stronę główną
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default ThanksPage;
